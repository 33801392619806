import React, { Suspense, lazy } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import WelcomeScreen from '../components/WelcomeScreen';

// Protected Screens
import ProtectedRoute from './protectedRoute';
const Home = lazy(() => import('../screens/Home'));

const routes = () => {
  return (
    <>
      <Suspense fallback={<WelcomeScreen />}>
        <HashRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route exact path='/' element={<Home />} />
            </Route>
          </Routes>
        </HashRouter>
      </Suspense>
    </>
  )
}

export default routes;
